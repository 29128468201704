import Projects from '../components/Projects';
import Donations from '../components/Donations';
import LatestDonations from '../components/LatestDonations';

function Home(props) {
  const { donations, data } = props;

  var domain = window.location.host;

  return (
    <div className="bg-white">
      <main>
        {/* Hero section */}
        <div className="relative">
          <div className="absolute inset-x-0 bottom-0 h-1/2 bg-gray-100" />
          <div className="max-w-12xl mx-auto sm:px-0">
            <div className="relative shadow-xl sm:overflow-hidden">
              <div className="absolute inset-0">
                <img className="h-full w-full object-cover" src="/images/bg1.jpg" alt="Volcanic Eruption" />
                <div
                  className="absolute inset-0 bg-gradient-to-r from-green-900 to-yellow-900"
                  style={{ mixBlendMode: 'multiply' }}
                />
              </div>
              {/* <div className="relative px-4 py-16 sm:px-6 lg:py-32 lg:px-8"> */}
              <div className="relative px-4 py-16 sm:px-6 lg:py-12 lg:px-8">
                <img className="center mx-auto w-24" src="/images/vincystrong.png" alt="Fist" />
                <h1 className="text-center text-4xl font-extrabold tracking-tight sm:text-5xl lg:text-6xl">
                  <span className="block text-yellow-100 py-4 sm:text-3xl lg:text-4xl">Vincy Strong</span>
                  <span className="block text-3xl md:text-5xl text-green-100">Volcano Relief Directory</span>
                </h1>
                <p className="mt-6 max-w-lg mx-auto text-center text-md md:text-xl text-white sm:max-w-3xl">
                  A directory of relief efforts for the people of St. Vincent and the Grenadines affected by the recent
                  vocanic eruption.
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* Latest */}
        <LatestDonations donations={donations} />

        {/* Live Stream Info */}
        <div className="bg-gray-100">
          <div className="max-w-7xl mx-auto py-16 pb-0 px-4 sm:px-6 lg:px-8">
            <div className="relative pb-16 ">
              <h2 className="text-center text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                Volcano Relief Live Stream
              </h2>
              <p className="mt-4 max-w-3xl mx-auto text-center text-xl text-gray-500">
                Live Stream from Friday April 17th
              </p>
              <div className="pt-10 justify-center items-center">
                <div className="twitch">
                  <div className="twitch-video">
                    <iframe
                      src={`https://player.twitch.tv/?video=990119973&parent=${domain}`}
                      frameBorder={0}
                      allowFullScreen={true}
                      scrolling="no"
                      height={378}
                      width={620}
                      title="Video Player"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Relief Efforts */}
        <div className="bg-gray-100">
          <div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
            <div className="relative pb-16 ">
              <h2 className="text-center text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                Relief Directory
              </h2>
              <p className="mt-4 max-w-3xl mx-auto text-center text-xl text-gray-500">
                We aren't affiliated with the efforts below. We are simply providing a list so you can evaluate and help
                however you can.
              </p>
            </div>
            <Projects data={data} />
          </div>
        </div>

        {/* Donations */}
        <Donations donations={donations} />

        <div className="max-w-12xl mx-auto sm:px-0">
          <div className="relative px-4 py-16 sm:px-6 lg:py-32 lg:px-8">
            <p className="mt-6 max-w-lg mx-auto text-center text-md md:text-xl text-black sm:max-w-3xl">
              The La Soufrière volcano located in the northern half of the beautiful island of St. Vincent and the
              Grenadines has erupted on April 9th. The northern half of the island was ordered to evacuate. Thousands of
              people are currently displaced.
            </p>
            <p className="mt-6 max-w-lg mx-auto text-center text-md md:text-xl text-black sm:max-w-3xl">
              VincyStrong.com is a directory of relief efforts for the people of St. Vincent and the Grenadines affected
              by the recent eruptions.
            </p>
            <p className="mt-6 max-w-lg mx-auto text-center text-md md:text-xl text-black sm:max-w-3xl">
              The Government of St. Vincent and the Grenadines are doing everything within their power to ensure that
              the citizens are safe and cared for.
            </p>
            <p className="mt-6 max-w-lg mx-auto text-center text-md md:text-xl text-black sm:max-w-3xl">
              Have you or someone you know started a relief effort? If so please send details to
              wearevincystrong@gmail.com to be added to our list.
            </p>
            <p className="mt-6 max-w-lg mx-auto text-center text-md md:text-xl text-black sm:max-w-3xl">
              We aren't affiliated with any of the donation efforts listed. We're simply providing a directory of relief
              efforts so that people who are willing and able to help can do so.
            </p>
          </div>
        </div>
      </main>
      <footer className="bg-gray-50" aria-labelledby="footerHeading">
        <h2 id="footerHeading" className="sr-only">
          Footer
        </h2>
        {/* This example requires Tailwind CSS v2.0+ */}
        <footer className="bg-white">
          <div className="max-w-7xl mx-auto py-12 px-4 overflow-hidden sm:px-6 lg:px-8">
            <div className="mt-8 flex justify-center space-x-6">
              <a href="https://www.instagram.com/wearevincystrong" className="text-green-700 hover:text-black">
                <span className="sr-only">Instagram</span>
                <svg className="h-16 w-16" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                  <path
                    fillRule="evenodd"
                    d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
                    clipRule="evenodd"
                  />
                </svg>
              </a>
            </div>
            <p className="mt-8 text-center text-base text-gray-400">@WeAreVincyStrong 🇻🇨 </p>
          </div>
        </footer>
        {/* Logo Cloud */}
        <div className="">
          <div className="py-16 px-8">
            <p className="text-center text-sm font-semibold uppercase text-gray-500 py-4 ">Built by the teams at</p>
            <div className="flex justify-center items-center opacity-50" style={{ marginLeft: 20 }}>
              <a href="https://www.fetelist.com">
                <img className="w-12" src="/images/logo-fetelist.svg" alt="FETE LIST" />
              </a>
              <a href="https://www.codeandvibes.com">
                <img className="w-40 p-5" src="/images/logo-codeandvibes.svg" alt="FETE LIST" />
              </a>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default Home;
