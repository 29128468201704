function Donations(props) {
  let { donations = [] } = props;

  return (
    <div className="bg-gray-100">
      <div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
        <div className="relative pb-16 ">
          <h2 className="text-center text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
            Latest Live Donations
          </h2>
        </div>

        <div className="">
          <div className="grid grid-cols-1 gap-4 md:grid-cols-3  lg:grid-cols-4">
            {donations.map((x, i) => {
              return (
                <div
                  key={i}
                  className="relative rounded-lg border border-gray-300 bg-white px-6 py-5 shadow-sm flex items-center space-x-3 hover:border-gray-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
                >
                  <div className="flex-shrink-0">
                    <img className="h-10 w-10 rounded-full" src="/images/cashapp.png" alt="" />
                  </div>
                  <div className="flex-1 min-w-0">
                    <a
                      href="https://cash.app/$FETELIST"
                      className="focus:outline-none"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <span className="absolute inset-0" aria-hidden="true" />
                      <p className="text-sm font-medium text-gray-900">{x.name}</p>
                      <p className="text-sm text-gray-500 truncate">{x.amount}</p>
                    </a>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Donations;
