export function donationsTransform(donations) {
  if (!donations || !Object.keys(donations)) return [];
  // Transform object into an array
  let donationObj = Object.keys(donations).reduce((all, id) => {
    const donation = getPerson(donations[id].subject);
    all.push({ id, ...donations[id], ...donation });
    return all;
  }, []);

  donationObj.reverse();

  // Sort list by date
  // donationObj.sort((a, b) => {
  //   if (a.id > b.id) return -1;
  //   if (a.id < b.id) return 1;
  //   return 0;
  // });
  //
  // console.log(donationObj);
  return donationObj;
}

export function getPerson(sub) {
  // FETE LIST sent you $100 for Donation!!
  // You sent $330 to Picky Eaters for Frontline Fridays Meals for Tomorrow
  let name = '';
  let paid = 0;
  let amount = 0;

  if (sub.includes('sent you')) {
    const nameArr = sub.split('sent you');
    amount = nameArr[1].split(' for')[0];
    name = nameArr[0];
  } else {
    const paidArr = sub.split('You sent');
    const nameArr = paidArr[1].split(' to ');
    paid = nameArr[0];
    name = nameArr[1].split(' for ')[0];
  }

  return { name, amount, paid };
}
