const data = [
  {
    title: 'Vincy Strong Volcano Relief Drive',
    location: {
      title: 'New York City',
      coordinates: '',
    },
    people: ['Naphtali', 'Janell'],
    tags: ['Supplies', 'Donations'],
    link: {
      text: 'https://www.instagram.com/p/CNc-lu7Fjld/',
      type: 'url',
    },
    image: '/projects/project-1.jpg',
  },
  {
    title: 'Oxygen Band House, Bottom Town',
    location: {
      title: 'St. Vincent',
      coordinates: '',
    },
    people: ['Karen Veira', 'Niasha'],
    tags: ['Supplies', 'Donations'],
    link: {
      text: 'oxygenmassvg@gmail.com',
      type: 'mailto',
    },
  },
  {
    title: 'Trinidad',
    location: {
      title: 'Trinidad',
      coordinates: '',
    },
    people: ['Trevlyn', 'Shomari'],
    tags: ['Supplies', 'Donations'],
    link: {
      text: 'readymix@gmail.com',
      type: 'mailto',
    },
  },
  {
    title: 'UK-SVG Combined Response',
    location: {
      title: 'United Kingdom',
      coordinates: '',
    },
    people: ['UK-SVG Friendship Trust'],
    tags: ['Donations'],
    link: {
      text: 'https://www.gofundme.com/f/uksvg-combined-response-to-la-soufriere-eruption',
      type: 'url',
    },
  },
  {
    title: 'KLC Shippers',
    location: {
      title: 'Toronto, Canada',
      coordinates: '',
    },
    people: ['KLC Shippers'],
    tags: ['Supplies'],
    link: {
      text: 'https://www.instagram.com/p/CNeBI7PlojG/',
      type: 'url',
    },
  },
  {
    title: "Pocket Doc's St. Vincent La Soufrière Relief Fund",
    location: {
      title: 'Toronto, Canada',
      coordinates: '',
    },
    people: ['Oliver DeCaul'],
    tags: ['Donations'],
    link: {
      text: 'https://gofund.me/e461d670',
      type: 'url',
    },
  },
  {
    title: 'Freaks Mas Camp',
    location: {
      title: 'New York City',
      coordinates: '',
    },
    people: ['Oliver DeCaul'],
    tags: ['Donations'],
    link: {
      text: 'Mondaymagicsvg@gmail.com',
      type: 'mailto',
    },
  },
];

export default data;
