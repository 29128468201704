function Project(props) {
  const { data } = props;
  const { title, location = {}, people = [], tags = [], link, image } = data;

  const cta = {
    mailto: {
      url: `mailto:${link.text}`,
      text: `${link.text}`,
    },
    phone: {
      url: `tel:${link.text}`,
      text: `${link.text}`,
    },
    url: {
      url: `${link.text}`,
      text: `More Information`,
    },
  };

  const defaultImage = '/images/vincystrong-sq.png';

  return (
    <li className="col-span-1 flex flex-col text-center bg-white rounded-lg shadow divide-y divide-gray-200">
      <div className="flex-1 flex flex-col p-8">
        {image && <img className="w-48 h-48 flex-shrink-0 mx-auto bg-black rounded-full" src={image} alt="" />}
        {!image && <img className="w-48 h-48 flex-shrink-0 mx-auto bg-black rounded-full" src={defaultImage} alt="" />}
        <h3 className="mt-6 text-gray-900 text-lg font-bold">{title}</h3>
        <dl className="mt-1 flex-grow flex flex-col justify-between">
          <dd className="text-gray-500 text-sm">{people.join(', ')}</dd>
          <dd className="mt-3">
            <span className="px-2 py-1 text-green-800 text-sm font-bold bg-red-100 rounded-full">{location.title}</span>
          </dd>
          <dd className="mt-3">
            {tags.map((x, i) => {
              return (
                <span key={i} className="px-2 py-1 text-green-800 text-xs font-medium bg-green-100 rounded-full">
                  {x}
                </span>
              );
            })}
          </dd>
        </dl>
      </div>
      <div>
        <div className="-mt-px flex divide-x divide-gray-900 bg-green-500">
          <div className="w-0 flex-1 flex">
            <a
              href={cta[link?.type]?.url}
              target="_blank"
              rel="noreferrer"
              className="relative -mr-px w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-white font-bold border border-transparent rounded-bl-lg hover:text-green-100"
            >
              {images[link?.type]}
              <span className="ml-3">{cta[link.type]?.text}</span>
            </a>
          </div>
        </div>
      </div>
    </li>
  );
}

const images = {
  url: (
    <svg
      aria-hidden="true"
      data-prefix="fas"
      data-icon="info-circle"
      className="svg-inline--fa fa-info-circle fa-w-16 w-6 text-white"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill="currentColor"
        d="M10.118.494a9.407 9.407 0 10.001 18.813A9.407 9.407 0 0010.118.494zm0 4.172a1.594 1.594 0 110 3.187 1.594 1.594 0 010-3.187zm2.124 9.635a.455.455 0 01-.455.455H8.449a.455.455 0 01-.454-.455v-.911c0-.251.203-.455.454-.455h.455v-2.428h-.455a.455.455 0 01-.454-.455v-.91c0-.251.203-.456.454-.456h2.429c.251 0 .455.205.455.456v3.793h.454c.252 0 .455.204.455.455v.911z"
      />
    </svg>
  ),
  mailto: (
    <svg
      className="w-5 h-5  text-white"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      fill="currentColor"
      aria-hidden="true"
    >
      <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
      <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
    </svg>
  ),
  phone: (
    <svg
      className="w-5 h-5  text-white"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      fill="currentColor"
      aria-hidden="true"
    >
      <path d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z" />
    </svg>
  ),
};

export default Project;
