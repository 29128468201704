function Stream(props) {
  const { donations } = props;

  return (
    <div>
      {/* <div className="bg-green-500"> */}
      <div className="py-2 pb-10 px-4 sm:px-2">
        <div className="text-gray-100 font-black p-2 text-xs uppercase">Latest Donations $FETELIST</div>
        <div className="">
          <div className="grid grid-cols-1 gap-4 md:grid-cols-3  lg:grid-cols-4">
            {donations.map((x, i) => {
              return (
                <div
                  key={i}
                  className="relative rounded-lg border border-blue-600 bg-blue-700 px-2 py-1 shadow-sm flex items-center space-x-3 "
                >
                  <div className="flex-1 min-w-0">
                    <span className="absolute inset-0" aria-hidden="true" />
                    <p className="text-lg text-gray-100 font-bold">{x.name}</p>
                    <p className="text-md text-gray-300 truncate font-medium">{x.amount}</p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Stream;
