const config = {
  firebase: {
    apiKey: 'AIzaSyCcwAoy9_YI_YvR2KqWyLchZTlzKp3ZvvI',
    projectId: 'wevincystrong',
    databaseURL: 'https://wevincystrong-default-rtdb.firebaseio.com',
    authDomain: 'wevincystrong.firebaseapp.com',
  },
};

export default config;
