import Project from './Project';

function Projects(props) {
  const { data } = props;

  return (
    <section className="com-projects">
      <ul className="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3">
        {data.map((x, i) => {
          return <Project key={i} data={x} />;
        })}
      </ul>
    </section>
  );
}

export default Projects;
