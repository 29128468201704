import React, { useEffect, useState } from 'react';

import Home from './pages/Home';
import Stream from './pages/Stream';

import config from './config';
import firebase from 'firebase/app';
import 'firebase/database';
import data from './data/data';

import { donationsTransform } from './helpers';

var pathname = window.location.pathname;

if (!firebase.apps.length) {
  firebase.initializeApp(config.firebase);
} else {
  firebase.app();
}

function App() {
  const [donations, setDonations] = useState([]);
  useEffect((donations) => {
    const fetch = async () => {
      await fetchDonations();
      return false;
    };
    const fetchDonations = async () => {
      firebase
        .database()
        .ref('donations')
        .orderByChild('id')
        .limitToLast(50)
        .on('value', (snapshot) => {
          const donations = donationsTransform(snapshot.val());
          setDonations(donations);
        });
      return false;
    };
    if (!donations?.length) fetch();
  }, []);

  const embed = pathname === '/embed';

  return (
    <div className="App">
      {embed && <Stream donations={donations} data={data} />}
      {!embed && <Home donations={donations} data={data} />}
    </div>
  );
}

export default App;
